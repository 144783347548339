<template>
  <a-drawer :visible="visible" width="50%" :maskClosable="false" @close="handleClose">
    <a-form-model :model="entity" @submit.prevent="handleSave">
      <a-form-model-item label="名称" prop="name" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-input v-model="entity.name" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item label="简介" prop="description" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-textarea v-model="entity.description" :autoSize="{ minRows: 2, maxRows: 6 }" allowClear />
      </a-form-model-item>
      <a-form-model-item label="Logo" prop="properties.logo" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <y-upload v-model="entity.properties.logo" :maximumQuantity="1" :minimumQuantity="1" type="picture"
          accept="image/*"></y-upload>
      </a-form-model-item>
      <a-form-model-item label="展位号" prop="booth" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-input v-model="entity.booth" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item label="banner位图集" prop="properties.album" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <y-upload v-model="entity.properties.album" :maximumQuantity="12" :minimumQuantity="1" type="picture"
          accept="image/*"></y-upload>
      </a-form-model-item>
      <a-form-model-item label="banner位视频" prop="properties.video" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <y-upload v-model="entity.properties.video" :maximumQuantity="1" :minimumQuantity="1" accept="video/*">
        </y-upload>
      </a-form-model-item>
      <!-- <a-form-model-item
        label="排序"
        prop="sequence"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input-number
          v-model="entity.sequence"
          :defaultValue="1"
          :min="0"
        ></a-input-number>
      </a-form-model-item> -->
      <a-form-model-item label="状态" prop="enabled" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-switch v-model="entity.enabled" />
      </a-form-model-item>
      <a-form-model-item label="公司介绍" prop="document.content" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <y-rich-text-editor v-model="entity.document.content"></y-rich-text-editor>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 8, offset: 4 }">
        <a-space>
          <a-button htmlType="submit" :loading="loading" type="primary">
            保存
          </a-button>
          <a-button :loading="loading" @click="handleClose"> 取消 </a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import ExhibitorService from "../../../services/exhibitor";
const defaultEntity = {
  enabled: true,
  exhibitNumber: 0,
  favoriteNumber: 0,
  collectNumber: 0,
  browseNumber: 0,
  sequence: 0,
  properties: {},
  document: {
    format: "HTML",
  },
};

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    target: {
      type: Object,
    },
  },
  data() {
    return {
      service: ExhibitorService,
      entity: JSON.parse(JSON.stringify(defaultEntity)),
    };
  },
  watch: {
    target: {
      handler: function name(value) {
        if (!value.properties) {
          value.properties = {};
        }

        if (!value.document) {
          value.document = {
            format: "HTML",
          };
        }

        this.entity = value;
      },
      deep: true,
    },
  },
  methods: {
    handleClose() {
      this.entity = JSON.parse(JSON.stringify(defaultEntity));

      this.$emit("close");

      this.$emit("update:visible", false);
      this.$emit("update:loading", false);
    },
    async handleSave() {
      try {
        this.$emit("update:loading", true);
        if (!this.entity.id) {
          const entity = {
            ...JSON.parse(JSON.stringify(defaultEntity)),
            ...this.entity,
          };

          await this.service.addEntity(entity);
        } else {
          await this.service.updateEntity(this.entity);
        }
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.entity = JSON.parse(JSON.stringify(defaultEntity));

        this.$emit("close");
        this.$emit("update:visible", false);
        this.$emit("update:loading", false);
      }
    },
  },
};
</script>

<style>
</style>